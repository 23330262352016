import { Typography } from '@mui/material';

const Privacy = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>プライバシーポリシー</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        本規約は、当グループが模擬試験（以下「本サービス」といいます。）を提供するにあたり、ご利用される皆様（以下「利用者」といいます。）の個人に関する情報（以下「個人情報」といいます。）を取得します。
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第１条（適用範囲）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        本プライバシーポリシー（以下「本ポリシー」といいます。）は、当グループが利用者から個人情報を取得、利用及び管理するときに適用されます。
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第２条（取得する情報）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        当グループは、利用者から以下の情報を取得します。<br />
        (1) 国ごとのアクセス<br />
        (2) 閲覧したURL及び日時に関するタイムスタンプ<br />
        (3) OSの種類<br />
        (4) ブラウザの種類<br />
        (5) デバイスの種類
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第３条（利用目的）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        当グループが個人情報を収集・利用する目的は、以下のとおりです。<br />
        <br />
        (1) 本サービスの提供・運営のため<br />
        (2) 不正アクセス、不正利用の防止のため <br />
        (3) 上記の利用目的に付随する目的
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第４条（Cookieの利用）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        当グループは、ウェブサイト及び本サービス（以下、これらを総称して「ウェブサイト等」といいます。）のアクセス及び利用状況の分析、広告配信並びにウェブサイト等の利便性向上のために、ウェブサイト等にアクセスした利用者の情報通信端末に保存されるCookie等のファイル及びこれに類似する技術を取得することがあります。当グループは、Cookie等と利用者の個人情報を関連付けることがあります。当グループは、利用者の個人情報と関連付けたCookie等が利用者の個人情報であることに鑑み、当該Cookie等を本ポリシーの定めに従って管理いたします。また、当グループは、Cookie等の情報を第三者が運営するデータ・マネジメント・プラットフォームに提供し、当該第三者からCookie等により収集されたウェブの閲覧履歴及びその分析結果を取得し、これらを利用者の個人データと結び付けた上で、広告配信等の目的で利用することがあります。
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第５条（安全確保の措置）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        当グループは、収集した情報の漏えい、滅失又はき損の防止その他収集した情報の適切な管理のために必要な措置を講じます。当グループが、安全管理のために講じた措置の概要は以下のとおりです。措置の具体的内容については、本ポリシーで定める窓口に対する利用者からの求めに応じて遅滞なく回答いたします。<br />
        (1) 基本方針の策定、個人情報の取扱いに係る規律の整備 <br />
        (2) 個人情報を取り扱うことのできる機器やアクセス権者を明確にし、個人情報への不要なアクセスを防止 <br />
        (3) 個人情報を取り扱う機器等のOSを最新の状態に保持<br />
        (4) 個人情報を取り扱う機器等にセキュリティ対策ソフトウェア等を導入
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第６条（個人情報の第三者への提供）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        1.当グループは、次に掲げる場合を除いて、あらかじめ利用者の同意を得ないで、取得した個人情報を第三者に提供することはありません。
        <br />
        (1) 法令に基づく場合<br />
        (2) 人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難であるとき。<br />
        (3) 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難であるとき。<br />
        (4) 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。<br />
        (5) その他法令で第三者提供の制限の例外が認められている場合<br />
        2.前項の定めにかかわらず、次に掲げる場合には、当該個人情報の提供先は第三者に該当しないものとします。<br />
        (1) 当グループが利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って当該個人情報が提供される場合<br />
        (2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合<br />
        (3) 第7条に定める共同利用者に対して提供される場合
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第７条（本プライバシーポリシーの変更）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        当グループは、法令改正への対応の必要性及び事業上の必要性に応じて、本ポリシーを変更する場合があります。本ポリシーの変更を行った場合には、本ウェブサイト上に掲載します。
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第８条（開示、訂正等の手続）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        １.利用者は、本条及び当グループの関連規程に従って、当グループに対し、個人情報保護法において認められる限度で、以下の求め又は請求を行うことができます。<br />
        (1) 個人情報の利用目的の通知の求め<br />
        (2) 個人情報又は第三者提供記録の開示の請求<br />
        (3) 個人情報の訂正、追加又は削除の請求<br />
        (4) 個人情報の利用の停止の請求<br />
        (5) 個人情報の第三者への提供の停止の請求<br />
        2.前項の求め又は請求にあたっては、同項各号のうちいずれの請求か特定の上、本人確認のための書類（運転免許証、健康保険証、住民票の写し等）をご提出頂きます。
      </Typography>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>第９条（お問い合わせ）</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        当グループの個人情報の取扱いに関するご相談や苦情等のお問い合わせについては、お問い合わせのところから詳細をご確認ください。
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }} textAlign="right">以上</Typography>
    </>
  );
};

export default Privacy;
